import EditableViewModel from "@/chipply/EditableViewModel";
import ProcessInfo from "@/chipply/reports/ProcessInfo";
import WorkOrderReportToggleOptions from "@/chipply/reports/WorkOrder/workOrderReportToggleOptions";
import { IEdit } from "chipply-common";
import _ from "lodash";

export class WorkOrderReportOptionsViewModel
    extends EditableViewModel
    implements IEdit<WorkOrderReportOptionsViewModel>
{
    public includeDealerName = false;
    public includeSalesPersonName = false;
    public includeDealerLogo = false;
    public includeAllProcess = false;
    public processes: ProcessInfo[] = [];
    public includeAccountingSummary = false;
    public includeAccountingSummaryNotes = false;
    public includeAccountingSummaryOperationalFees = false;
    public includeAccountingSummaryManualAdjustmentsRight = false;
    public includeAccountingSummaryManualAdjustmentsLeft = false;
    public includeProducts = false;
    public includeProductThumbnails = false;
    public includeProductCost = false;
    public includeProductPrice = false;
    public includeProductAndProcess = false;
    public includeFundraising = false;
    public includeOversizeCharges = false;
    public includeOptionCharges = false;
    public includeProductSummary = false;
    public showSizeChargesDetails = false;
    public showProcessComponentsDetails = false;
    public includeArtwork = false;
    public includeArtworkColorNames = false;
    public includeArtworkProductImages = false;
    public includeOptions = false;
    public includeOptionThumbnails = false;
    public originalModel: WorkOrderReportToggleOptions;

    public constructor(model: WorkOrderReportToggleOptions) {
        super();
        this.setFromModel(model);
        this.originalModel = this.toModel();
    }

    public reset() {
        const resetModel = new WorkOrderReportToggleOptions();
        for (const process of this.processes) process.checked = true;
        resetModel.processes = this.processes;

        this.setFromModel(resetModel);
    }

    public setFromModel(model: WorkOrderReportToggleOptions) {
        this.includeDealerName = model.includeDealerName;
        this.includeSalesPersonName = model.includeSalesPersonName;
        this.includeDealerLogo = model.includeDealerLogo;
        this.includeAllProcess = model.includeAllProcess;
        this.processes = model.processes;
        this.includeAccountingSummary = model.includeAccountingSummary;
        this.includeAccountingSummaryNotes = model.includeAccountingSummaryNotes;
        this.includeAccountingSummaryOperationalFees = model.includeAccountingSummaryOperationalFees;
        this.includeAccountingSummaryManualAdjustmentsLeft = model.includeAccountingSummaryManualAdjustmentsLeft;
        this.includeAccountingSummaryManualAdjustmentsRight = model.includeAccountingSummaryManualAdjustmentsRight;
        this.includeProducts = model.includeProducts;
        this.includeProductThumbnails = model.includeProductThumbnails;
        this.includeProductCost = model.includeProductCost;
        this.includeProductPrice = model.includeProductPrice;
        this.includeProductAndProcess = model.includeProductAndProcess;
        this.includeFundraising = model.includeFundraising;
        this.includeOversizeCharges = model.includeOversizeCharges;
        this.includeOptionCharges = model.includeOptionCharges;
        this.includeProductSummary = model.includeProductSummary;
        this.showSizeChargesDetails = model.showSizeChargesDetails;
        this.showProcessComponentsDetails = model.showProcessComponentsDetails;
        this.includeArtwork = model.includeArtwork;
        this.includeArtworkColorNames = model.includeArtworkColorNames;
        this.includeArtworkProductImages = model.includeArtworkProductImages;
        this.includeOptions = model.includeOptions;
        this.includeOptionThumbnails = model.includeOptionThumbnails;
    }

    public createEditViewModel(): WorkOrderReportOptionsViewModel {
        const model = this.toModel();
        return new WorkOrderReportOptionsViewModel(model);
    }

    public apply(vm: WorkOrderReportOptionsViewModel) {
        const model = vm.toModel();
        const results = {
            modelSame: _.isEqual(model, this.toModel()),
            needsServerRefresh: this.hasPricingChange(model) || this.hasNewProcessSelection(model),
        };
        this.setFromModel(model);
        return results;
    }

    public accountingSummaryChanged() {
        if (!this.includeAccountingSummary) return;
        this.includeAccountingSummaryNotes = true;
        this.includeAccountingSummaryOperationalFees = true;
    }

    public processesChanged() {
        if (!this.includeAllProcess) return;
        for (const process of this.processes) {
            process.checked = true;
        }
    }

    public productsChanged() {
        if (!this.includeProducts) return;
        this.includeProductCost = false;
        this.includeProductThumbnails = false;
        this.includeProductPrice = true;
        this.includeProductAndProcess = false;
        this.includeFundraising = false;
        this.includeOversizeCharges = false;
        this.includeOptionCharges = false;
    }

    public summaryChanged() {
        if (!this.includeProductSummary) return;
        this.showSizeChargesDetails = false;
        this.showProcessComponentsDetails = true;
    }

    public artworkChanged() {
        if (!this.includeArtwork) return;
        this.includeArtworkColorNames = true;
        this.includeArtworkProductImages = true;
    }

    public unselectPricingOptions() {
        this.includeProductCost = false;
        this.includeProductPrice = false;
        this.includeProductAndProcess = false;
        this.includeFundraising = false;
        this.includeOversizeCharges = false;
        this.includeOptionCharges = false;
    }

    public unselectProductThumbnails() {
        this.includeProductThumbnails = false;
    }

    protected hasPricingChange(newModel: WorkOrderReportToggleOptions) {
        if (this.includeProductAndProcess !== newModel.includeProductAndProcess) return true;
        if (this.includeFundraising !== newModel.includeFundraising) return true;
        if (this.includeOptionCharges !== newModel.includeOptionCharges) return true;
        if (this.includeOversizeCharges !== newModel.includeOversizeCharges) return true;
        return false;
    }

    public toModel(): WorkOrderReportToggleOptions {
        const options = new WorkOrderReportToggleOptions();
        options.includeDealerName = this.includeDealerName;
        options.includeSalesPersonName = this.includeSalesPersonName;
        options.includeDealerLogo = this.includeDealerLogo;
        options.includeAccountingSummary = this.includeAccountingSummary;
        options.includeAccountingSummaryNotes = this.includeAccountingSummaryNotes;
        options.includeAccountingSummaryOperationalFees = this.includeAccountingSummaryOperationalFees;
        options.includeAccountingSummaryManualAdjustmentsLeft = this.includeAccountingSummaryManualAdjustmentsLeft;
        options.includeAccountingSummaryManualAdjustmentsRight = this.includeAccountingSummaryManualAdjustmentsRight;
        options.includeProducts = this.includeProducts;
        options.includeProductThumbnails = this.includeProductThumbnails;
        options.includeProductCost = this.includeProductCost;
        options.includeProductPrice = this.includeProductPrice;
        options.includeProductAndProcess = this.includeProductAndProcess;
        options.includeFundraising = this.includeFundraising;
        options.includeOptionCharges = this.includeOptionCharges;
        options.includeOversizeCharges = this.includeOversizeCharges;
        options.includeProductSummary = this.includeProductSummary;
        options.showSizeChargesDetails = this.showSizeChargesDetails;
        options.showProcessComponentsDetails = this.showProcessComponentsDetails;
        options.includeArtwork = this.includeArtwork;
        options.includeArtworkColorNames = this.includeArtworkColorNames;
        options.includeArtworkProductImages = this.includeArtworkProductImages;
        options.includeOptions = this.includeOptions;
        options.includeOptionThumbnails = this.includeOptionThumbnails;
        options.includeAllProcess = this.includeAllProcess;

        options.processes = [];
        for (const process of this.processes) {
            const copy = new ProcessInfo(process.process, process.date, process.processDescription);
            copy.checked = process.checked;
            options.processes.push(copy);
        }
        return options;
    }

    public toSelectedProcesses() {
        const processes = [];
        for (const process of this.processes) {
            processes.push({
                processSelected: process.checked,
                processNumber: process.process.value,
                dueDate: new Date(), // TODO: hook up if needed,
                processName: process.process.text,
                processDescription: process.processDescription,
            });
        }

        return processes;
    }

    private hasNewProcessSelection(model: WorkOrderReportToggleOptions) {
        for (const process of model.processes) {
            if (!process.checked) continue;
            const wasProcessSelected = this.processes.find(
                (pi) => pi.process.value == process.process.value && pi.checked
            );
            if (!wasProcessSelected) return true;
        }

        return false;
    }
}
